import { useState, useEffect, useCallback } from "react";

const useSessionTimeout = (timeout = 600000) => { // Timeout em milissegundos (600000 = 10 minutos)
  const [isActive, setIsActive] = useState(true);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  const resetTimeout = useCallback(() => {
    setLastActivityTime(Date.now());
  }, []);

  const checkInactivity = useCallback(() => {
    const currentTime = Date.now();
    if (currentTime - lastActivityTime > timeout) {
      setIsActive(false); // Marca a sessão como inativa
    }
  }, [lastActivityTime, timeout]);

  useEffect(() => {
    const interval = setInterval(checkInactivity, 1000); // Verifica a cada 1 segundo
    return () => clearInterval(interval);
  }, [checkInactivity]);

  useEffect(() => {
    const events = ["mousemove", "keydown", "mousedown", "touchstart"];
    events.forEach(event => window.addEventListener(event, resetTimeout));

    return () => {
      events.forEach(event => window.removeEventListener(event, resetTimeout));
    };
  }, [resetTimeout]);

  return isActive;
};

export default useSessionTimeout;
